import { defineComponent } from 'vue';
import { getStepFunctionStatus } from '@/api/dataentry-api';
import date from 'quasar/src/utils/date.js';
export default defineComponent({
    props: {},
    data: () => {
        return {
            show: false,
            loading: false,
            syncStatus: 'CHECKING...',
            changesPending: 'NO',
            timeStarted: '',
            estimatedTime: '',
            lastUpdateUserEmail: '',
            elapsedTime: '',
            dataSyncUpdateList: [],
            columns: [
                {
                    field: 'status',
                    name: 'status',
                    label: 'STATUS',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'commencementTime',
                    name: 'commencementTime',
                    label: 'START TIME',
                    sortable: true,
                    align: 'center'
                },
                {
                    field: 'completedTime',
                    name: 'completedTime',
                    label: 'END TIME',
                    sortable: true,
                    align: 'center'
                },
                {
                    name: 'elapsedTime',
                    label: 'RUN TIME',
                    align: 'center',
                    sortable: true
                },
                {
                    field: 'lastUpdateUserEmail',
                    name: 'lastUpdateUserEmail',
                    label: 'USER',
                    sortable: true,
                    align: 'center'
                }
            ]
        };
    },
    created() {
        this.getSyncStatus();
    },
    computed: {
        authenticated() {
            return this.$store.state.authenticated;
        },
        buttonColour() {
            return 'positive';
        },
        disableSyncButton() {
            // Disable sync button if running or other unknown state
            if (this.syncStatus === 'RUNNING') {
                return true;
            }
            else {
                return false;
            }
        }
    },
    methods: {
        openDialog() {
            this.getSyncStatus();
            this.show = true;
        },
        calculateElapsedTime(startTime, endTime) {
            if (startTime && endTime) {
                const start = new Date(startTime);
                const end = new Date(endTime);
                const elapsed = Math.round((end.getTime() - start.getTime()) / 60000);
                return elapsed.toString();
            }
            return '';
        },
        startTransformProcess() {
            this.show = false;
            this.$store.dispatch('startDataWarehouseTransform');
        },
        async getSyncStatus() {
            this.loading = true;
            try {
                const result = await getStepFunctionStatus();
                this.dataSyncUpdateList = this.parseDataSyncLogEntries(result.dataSyncUpdateList || []);
                if (this.dataSyncUpdateList.length > 0) {
                    let lastUpdate = this.dataSyncUpdateList[0];
                    this.syncStatus = lastUpdate.status;
                    this.timeStarted = lastUpdate.commencementTime;
                    this.lastUpdateUserEmail = lastUpdate.lastUpdateUserEmail;
                    this.changesPending = lastUpdate.arePendingChangesPresent ? 'YES' : 'NO';
                    this.elapsedTime = this.calculateElapsedTime(lastUpdate.commencementTime, new Date().toString());
                }
                if (this.syncStatus === 'RUNNING') {
                    if (this.dataSyncUpdateList[1]) {
                        let lastUpdate = this.dataSyncUpdateList[1]; // don't get the current running process
                        this.estimatedTime = this.calculateElapsedTime(lastUpdate.commencementTime, lastUpdate.completedTime);
                    }
                    else {
                        this.estimatedTime = 'UNKNOWN';
                    }
                }
                // For whatever reason we don't display the first log entry in the table
                // if it is currently running
                if (this.syncStatus === 'RUNNING') {
                    this.dataSyncUpdateList.shift();
                }
            }
            catch (error) {
                this.syncStatus = 'UNKNOWN';
                console.log('Error : getSyncStatus: ', error);
            }
            finally {
                this.loading = false;
            }
        },
        parseDataSyncLogEntries(entries) {
            if (entries.length === 0) {
                return [];
            }
            entries.forEach((entry) => {
                entry.commencementTime = date.formatDate(new Date(entry.commencementTime), 'YYYY-MM-DD HH:mm');
                entry.completedTime = date.formatDate(new Date(entry.completedTime), 'YYYY-MM-DD HH:mm');
                entry.requestedTime = date.formatDate(new Date(entry.requestedTime), 'YYYY-MM-DD HH:mm');
                return entry;
            });
            entries.sort((a, b) => {
                return new Date(b.commencementTime).getTime() - new Date(a.commencementTime).getTime();
            });
            return entries;
        }
    }
});
