import { defineComponent } from 'vue';
import { activeConfig } from '@/config';
import SyncDataWarehouseDialog from '@/components/SyncDataWarehouseDialog.vue';
export default defineComponent({
    components: {
        SyncDataWarehouseDialog
    },
    data() {
        return {
            confirmTransformDialog: false,
            options: [
                'SUPPLIER',
                'CAT. 1 - PURCHASED GOODS & SERVICES',
                'CAT. 4 - UPSTREAM TRANSPORTATION & DISTRIBUTION',
                'CAT. 11 - USE OF SOLD PRODUCTS',
                'CAT. 12 - EOL TREATMENT OF SOLD PRODUCTS'
            ],
            selectedOption: 'SUPPLIER'
        };
    },
    created() {
        this.checkAuth();
    },
    mounted() {
        this.currentRoute();
    },
    computed: {
        authenticated() {
            return this.$store.state.authenticated;
        },
        logoutLink() {
            return activeConfig().apiBaseUrl + '/oauth/logout';
        },
        loginLink() { return activeConfig().apiBaseUrl + '/oauth/login/cognito'; },
        loginPage() {
            if (this.$route.name === 'login') {
                return true;
            }
            else {
                return false;
            }
        }
    },
    watch: {
        selectedOption(val) {
            switch (val) {
                case 'SUPPLIER':
                    this.$router.push({ name: 'Suppliers' });
                    break;
                case 'CAT. 1 - PURCHASED GOODS & SERVICES':
                    this.$router.push({ name: 'Cat. 1 - Purchased Goods & Services Records' });
                    break;
                case 'CAT. 4 - UPSTREAM TRANSPORTATION & DISTRIBUTION':
                    this.$router.push({ name: 'Cat. 4 - Upstream Transportation & Distribution Records' });
                    break;
                case 'CAT. 11 - USE OF SOLD PRODUCTS':
                    this.$router.push({ name: 'Cat. 11 - Use of Sold Products Records' });
                    break;
                case 'CAT. 12 - EOL TREATMENT OF SOLD PRODUCTS':
                    this.$router.push({ name: 'Cat. 12 - EOL Treatment of Sold Products Records' });
                    break;
                default: break;
            }
        }
    },
    methods: {
        goTo(location) {
            this.$router.replace(location);
        },
        async checkAuth() {
            ('check auth');
            try {
                await this.$store.dispatch('checkAuth');
            }
            catch (error) {
                console.log('MainLayout : checkAuth failed', error);
            }
        },
        currentRoute() {
            if (this.$route.name === 'Supplier') {
                this.selectedOption = 'SUPPLIER';
            }
            else if (this.$route.name === 'Cat. 1 - Purchased Goods & Services') {
                this.selectedOption = 'CAT. 1 - PURCHASED GOODS & SERVICES';
            }
            else if (this.$route.name === 'Cat. 4 - Upstream Transportation & Distribution') {
                this.selectedOption = 'CAT. 4 - UPSTREAM TRANSPORTATION & DISTRIBUTION';
            }
            else if (this.$route.name === 'Cat. 11 - Use of Sold Products') {
                this.selectedOption = 'CAT. 11 - USE OF SOLD PRODUCTS';
            }
            else if (this.$route.name === 'Cat. 12 - EOL Treatment of Sold Products') {
                this.selectedOption = 'CAT. 12 - EOL TREATMENT OF SOLD PRODUCTS';
            }
        }
    }
});
