import { render } from "./MainLayout.vue?vue&type=template&id=4f322dbb&scoped=true"
import script from "./MainLayout.vue?vue&type=script&lang=ts"
export * from "./MainLayout.vue?vue&type=script&lang=ts"

import "./MainLayout.vue?vue&type=style&index=0&id=4f322dbb&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-4f322dbb"

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QToolbarTitle,QItem,QItemSection,QSeparator,QBtn,QSelect,QSpace,QPageContainer});
